import { paramCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';
import { PATH_APP } from '../../routes/paths';
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';
// _mock_
import { _userList } from '../../_mock';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../components/table';
// sections
import { ApiEndpoints } from '../../config';
import axios from '../../utils/axios';
import Drawer from '@mui/material/Drawer';
import { useSnackbar } from 'notistack';
import BusinessSettingsTableToolbar from '../../sections/@dashboard/businessSettings/list/BusinessSettingsTableToolbar';
import BusinessSettingsTableRow from '../../sections/@dashboard/businessSettings/list/BusinessSettingsTableRow';
import AddNewUnsubscriptionRules from '../../sections/@dashboard/businessSettings/AddNewUnsubscriptionRules';
import AddNewDataExtractionRules from '../../sections/@dashboard/businessSettings/AddNewDataExtractionRules';
import AddNewAutoReplyRules from '../../sections/@dashboard/businessSettings/AddNewAutoReplyRules';
import AddNewMessageRoutingConfig from '../../sections/@dashboard/businessSettings/AddNewMessageRoutingConfig';
import TableSkeleton from 'src/components/skeleton/TableSkeleton';

// ----------------------------------------------------------------------

export default function BusinessSettings() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [businessLoading, setBusinessLoading] = useState(true);
  
  const [filterName, setFilterName] = useState('');
  
  const [filterRole, setFilterRole] = useState('unsubscription rules');

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('unsubscription rules');
  
  const [departments, setDepartments] = useState([]);
  const [isEdit, setIsEdit] = useState();
  const [webhookEndPoints, setWebhookEndPOints] = useState([]);

  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    setIsEdit(null);
  };

  useEffect(() => {
    setLoading(true);
  }, []);

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };

  const handleDeleteRow = async (id) => {
    const apiEndPoint =
      filterStatus === 'unsubscription rules'
        ? ApiEndpoints.DELETE_UNSUBSCRIPTON_RULES
        : filterStatus === 'auto reply rules'
        ? ApiEndpoints.DELETE_AUTO_REPLY
        : filterStatus === 'data extraction rules'
        ? ApiEndpoints.DELETE_DATA_EXTRACTION_RULES
        : ApiEndpoints.DELETE_MESSAGE_ROUTING_CONFIGURATION;
    await axios
      .delete(apiEndPoint + '/' + id)
      .then((res) => {
        if (res.data.status === 200) {
          enqueueSnackbar(res.data.message);
          getUnsubscriptionRulesList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = departments.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setDepartments(deleteRows);
  };
  const handleEditRow = (id) => {
    toggleDrawer(id);
    setIsEdit(id);
  };

  const dataFiltered = applySortFilter({
    departments,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });
  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  const STATUS_OPTIONS = [
    'unsubscription rules',
    'data extraction rules',
    'auto reply rules',
    'message routing configuration',
  ];
  const TABLE_HEAD = [
    filterStatus == 'message routing configuration'
      ? { id: 'id', label: 'Channel Id', align: 'center' }
      : { id: 'name', label: 'Rule Name', align: 'center' },
    filterStatus == 'message routing configuration' && {
      id: 'configurationName',
      label: 'Configuration Name',
      align: 'center',
    },
    { id: 'communicationChannel', label: 'Communication Channel', align: 'center' },
    { id: 'description', label: 'View Detail', align: 'center' },
    { id: 'action', label: 'Action', align: 'center' },
  ].filter(Boolean);

  const getStatusCount = (status) => {
    // if (status === 'all') {
    return departments?.length;
    // }
    // return departments?.filter((item) => item?.status?.toLowerCase() === status)?.length;
  };
  const getUnsubscriptionRulesList = () => {
    setDepartments([]);
    const apiEndPoint =
      filterStatus === 'unsubscription rules'
        ? ApiEndpoints.UNSUBSCRIPTION_RULES_LIST
        : filterStatus === 'auto reply rules'
        ? ApiEndpoints.AUTO_REPLY_RULES_LIST
        : filterStatus === 'data extraction rules'
        ? ApiEndpoints.DATA_EXTRACTION_RULES_LIST
        : ApiEndpoints.MESSAGE_ROUTING_CONFIGURATION_LIST;
        setBusinessLoading(true)
    axios
      .get(apiEndPoint)
      .then((response) => {
        if (response?.data?.status === 200) {
          setDepartments(
            filterStatus === 'unsubscription rules'
              ? response?.data?.data?.unsubscriptionRules
              : filterStatus === 'auto reply rules'
              ? response?.data?.data?.auto_reply_rules
              : filterStatus === 'data extraction rules'
              ? response?.data?.data?.dataExtractionRules
              : response?.data?.data?.messageRoutingRules
          );
          (filterStatus === 'unsubscription rules' || filterStatus === 'auto reply rules') && setWebhookEndPOints(response?.data?.data?.businessEndPoints)
          setBusinessLoading(false)
        }
      })
      .catch((err) => {
        setBusinessLoading(false)
        console.log('Api call Error:', err)}) .finally(() => setBusinessLoading(false));
  };
  useEffect(() => {
    getUnsubscriptionRulesList();
  }, [filterStatus]);
  return (
    <>
      <Drawer anchor={'right'} open={drawerOpen} onClose={() => toggleDrawer()}>
        {filterStatus === 'unsubscription rules' ? (
          <AddNewUnsubscriptionRules isEdit={isEdit} webhookEndPoints={webhookEndPoints} toggleDrawer={toggleDrawer} getUnsubscriptionRulesList={getUnsubscriptionRulesList} />
        ) : filterStatus === 'data extraction rules' ? (
          <AddNewDataExtractionRules isEdit={isEdit} toggleDrawer={toggleDrawer} getUnsubscriptionRulesList={getUnsubscriptionRulesList} />
        ) : filterStatus === 'auto reply rules' ? (
          <AddNewAutoReplyRules isEdit={isEdit} webhookEndPoints={webhookEndPoints} toggleDrawer={toggleDrawer} getUnsubscriptionRulesList={getUnsubscriptionRulesList} />
        ) : filterStatus === 'message routing configuration' ? (
          <AddNewMessageRoutingConfig isEdit={isEdit} toggleDrawer={toggleDrawer} getUnsubscriptionRulesList={getUnsubscriptionRulesList} />
        ) : (
          <h4>No Data</h4>
        )}
      </Drawer>
      <Page title="Settings: List" style={{marginLeft:'20px', marginRight:'20px'}}>
        {/* <Container maxWidth={themeStretch ? false : 'lg'}> */}
          <HeaderBreadcrumbs
            heading="Business Settings"
            links={[
              { name: 'Dashboard', href: PATH_APP.general.dashboard },
              { name: 'Business Settings', href: PATH_APP.management.businessSettings },
            ]}
            action={
              <Button variant="contained" onClick={toggleDrawer} startIcon={<Iconify icon={'eva:plus-fill'} />}>
                Add {filterStatus}
              </Button>
            }
          />
          <Card>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={filterStatus}
              onChange={onChangeFilterStatus}
              sx={{ px: 2, bgcolor: 'background.neutral' }}
            >
              {STATUS_OPTIONS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab}
                  label={tab + (filterStatus === tab ? `(${getStatusCount(tab)})` : '')}
                  value={tab}
                  sx={{'& .MuiTabs-indicator': {
    backgroundColor: 'white', // Change the underline color of the active tab to white
  },}}
                />
              ))}
            </Tabs>
            <Divider />
            <BusinessSettingsTableToolbar
              filterName={filterName}
              filterRole={filterRole}
              onFilterName={handleFilterName}
              onFilterRole={handleFilterRole}
            />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                {selected.length > 0 && (
                  <TableSelectedActions
                    dense={dense}
                    numSelected={selected.length}
                    rowCount={departments.length}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        departments.map((row) => row.id)
                      )
                    }
                    actions={
                      <Tooltip title="Delete">
                        <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                          <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                      </Tooltip>
                    }
                  />
                )}
                <Table size={dense ? 'small' : 'medium'}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={departments.length}
                    numSelected={selected.length}
                    onSort={onSort}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        departments.map((row) => row.id)
                      )
                    }
                  />
                  <TableBody>
                  {businessLoading ? (
                    <TableSkeleton rowsPerPage={rowsPerPage} TABLE_HEAD={TABLE_HEAD} />
                  ) : (
                    <>
                    {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <BusinessSettingsTableRow
                        key={row.id}
                        row={row}
                        selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                        onEditRow={() => handleEditRow(row)}
                        activeTab={filterStatus}
                      />
                    ))}
                    <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, departments.length)} />
                    <TableNoData isNotFound={isNotFound} titleText={`Add new ${filterStatus}`} description={`You have no ${filterStatus} to view`} />
                    </>
                  )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <Box sx={{ position: 'relative',maxHeight:'54px'  }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={dataFiltered.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />
            </Box>
          </Card>
        {/* </Container> */}
      </Page>
    </>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ departments, comparator, filterName, filterStatus, filterRole }) {
  const stabilizedThis = departments.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  // departments = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    departments = departments.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  }

  // if (filterStatus !== 'all') {
  //   departments = departments.filter((item) => item?.status?.toLowerCase() === filterStatus);
  // }

  // if (filterRole !== 'all') {
  //   departments = departments.filter((item) => item.role === filterRole);
  // }

  return departments;
}
