import React from 'react'
import {Button, Container} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { DatePicker } from 'antd';
import { useState } from 'react';
import moment from 'moment';
import { Padding } from '@mui/icons-material';
import { ChatSidebar } from 'src/sections/@dashboard/chat';
const { RangePicker } = DatePicker;


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Department 1',
  'Department 2',
  'Department 3',
  'Department 4',
  'Department 5',
];
function getStyles(name, personName, theme) {
    return {
      fontWeight: personName.includes(name)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
    };
  }

const ITEM_HEIGHT1 = 48;
const ITEM_PADDING_TOP1 = 8;
const MenuProps1 = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT1 * 4.5 + ITEM_PADDING_TOP1,
      width: 250,
    },
  },
};

const names1 = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
];


const ChatHistory = () => {
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
    const [personName1, setPersonName1] = React.useState([]);

    const handleChange = (event) => {
        const {
          target: { value },
        } = event;
        setPersonName(
          typeof value === 'string' ? value.split(',') : value,
        );
      };
      
  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName1(
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const [dates, setDates] = useState([])
  console.log(dates)

  return (
    <section className='failed-message'>
        <Container>
            <div className='header'>
              <h1>Chat History</h1>
            </div>
            <div className='chat-range'>
               <div>
                    < RangePicker className='date-css'
                        onChange={(values) => {
                            setDates(values.map(item=>{
                            return  moment(item).format('YYYY-DD-MM')
                        }))
                        }}

                    />
                </div>
                <div className='failed-form-div'>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-name-label">Department</InputLabel>
                        <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Department" />}
                        MenuProps={MenuProps}
                        >
                        {names.map((name) => (
                            <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, personName, theme)}
                            >
                            {name}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-checkbox-label">Agent</InputLabel>
                        <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        value={personName1}
                        onChange={handleChange1}
                        input={<OutlinedInput label="Agent" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps1}
                        >
                        {names1.map((name) => (
                            <MenuItem key={name} value={name}>
                                <Checkbox checked={personName1.includes(name)} />
                                <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className='parnt-history'>
                <div className='child-1'></div>
                <div className='child-2'></div>
            </div>

        </Container>
    </section>
  )
}

export default ChatHistory
