import { paramCase } from 'change-case';
import { useEffect, useState, useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from '@mui/material';
import { PATH_APP } from '../../routes/paths';
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';
// _mock_
import { _userList } from '../../_mock';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import SideDrawer from '../../components/SideDrawer';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../components/table';
import { ChannelTableToolbar, ChannelTableRow } from '../../sections/@dashboard/channel/list';
import AddNewChannel from '../../sections/@dashboard/channel/AddNewChannel'
import axios from '../../utils/axios';
import { ApiEndpoints } from 'src/config';
import { ChannelContext } from '../../contexts/ChannelsContext';
import { useSnackbar } from 'notistack';
import TableSkeleton from 'src/components/skeleton/TableSkeleton';

const ROLE_OPTIONS = [
  'all',
  'Verified',
  'Configured',
  'Live',
];

const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'center' },
  { id: 'company_handle', label: 'Number', align: 'center' },
  { id: 'type', label: 'Type', align: 'center' },
  { id: 'isVerified', label: 'Verified', align: 'center' },
  { id: 'configured', label: 'Configured', align: 'center' },
  { id: 'lastCommunication', label: 'Last Communication', align: 'center' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
  { id: '' },
];


// ----------------------------------------------------------------------

export default function Channels() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const {enqueueSnackbar} = useSnackbar()

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [departmentChannels, setDepartmentChannels] = useState([]);

  const [channels, setChannels] = useState([])

  const [filterName, setFilterName] = useState('');

  const [filterRole, setFilterRole] = useState('all');

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');

  const channelsContext = useContext(ChannelContext)
  
  const STATUS_OPTIONS = ['all', 'live', 'On Hold'];
  const [selectedHeader,setSlectedHeader] = useState();

  const getStatusCount = (status) => {
    if (status === 'all') {
      return channelsContext?.channelList?.length;
    }
    return channelsContext?.channelList?.filter(item => item?.status?.toLowerCase() === status)?.length;
  };

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };


  const deleteChannel = async (id) => {
    await axios.delete(ApiEndpoints.DELETE_CHANNEL+'/'+id).then(res => {
        try {
            if(res.data.status === 200) {
              enqueueSnackbar(res.data.message)
                channelsContext.getChannels()
            }
        } catch(error) {
            console.log(error)
        }
    });
    
  };
  const handleDeleteRow = (id) => {
    channelsContext.deleteChannel(id)
    const deleteRow = channelsContext.channelList.filter((row) => row.id !== id);
    setSelected([]);
    setTableData(deleteRow);
  };

  const handleEditRow = (row) => {
    console.log('row', row)
    channelsContext.toggleDrawer({open: true, is_edit: true})
    channelsContext.editChannel(row)
  };

  const dataFiltered = applySortFilter({
    departmentChannels: channelsContext.channelList,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
    selectedHeader,
  });

  const getChannelTypes = async () => {
    const channels = await axios.get(ApiEndpoints.CREATE_CHANNEL)
    return channels
  }

  useEffect(() => {
    getChannelTypes().then(res => {
      if (res.data.status === 200) {
        const list = res?.data?.data?.channelTypes
        const options = list?.map(item => {
        return {
          label: item.name,
          value: item.id
        }
      })
      setChannels([...channels, ...options])
      }
      else {
        enqueueSnackbar(res.data.message, { variant: 'error' });
      }
    })

  }, [])

  useEffect(() => {
    channelsContext.getChannels()
  }, [])
  const handleHeaderClick = (headCell) => {
    setSlectedHeader(headCell);
  };
  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  return (
    <Page title="Channels" style={{marginLeft:'20px', marginRight:'20px'}}>
      {/* <Container maxWidth={themeStretch ? false : 'lg'}> */}
        <HeaderBreadcrumbs
          heading="My Communication Channels"
          links={[
            { name: 'Dashboard', href: PATH_APP.general.dashboard },
            { name: 'Channels', href: PATH_APP.general.channels },
          ]}
          action={
            <SideDrawer buttonText={'Create Channel'}>
              <AddNewChannel channels={channels} />
          </SideDrawer>
          }
        />

        <Card>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onChangeFilterStatus}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab + `(${getStatusCount(tab)})`} value={tab} />
            ))}
          </Tabs>

          <Divider />

          <ChannelTableToolbar
            filterName={filterName}
            filterRole={filterRole}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            optionsRole={ROLE_OPTIONS}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={departmentChannels.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      departmentChannels.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRow(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={channelsContext.channelList.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      departmentChannels.map((row) => row.id)
                    )
                  }
                  onHeaderClick={handleHeaderClick}
                />

                <TableBody>
                {channelsContext.channelList?.length < 1 ? (
                    <TableSkeleton rowsPerPage={rowsPerPage} TABLE_HEAD={TABLE_HEAD} />
                  ) : (
                    <>
                   {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <ChannelTableRow
                      key={row.id}
                      row={row}
                      selected={selected.includes(row.id)}
                      onSelectRow={() => onSelectRow(row.id)}
                      onDeleteRow={() => deleteChannel(row.id)}
                      onEditRow={() => handleEditRow(row)}
                    />
                  ))}

                  <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, channelsContext?.channelList?.length)} />

                  <TableNoData isNotFound={isNotFound} titleText={`Add new channels`} description={`You have no channels to view in ${filterStatus} tab`}/>
                  </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative', maxHeight:'54px'}}  >
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              style={{height:'52px', marginTop:'2px'}}
            />

            {/* <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            /> */}
          </Box>
        </Card>
      {/* </Container> */}
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ departmentChannels, comparator, filterName, filterStatus, filterRole ,selectedHeader}) {
//   const stabilizedThis = departmentChannels.map((el, index) => [el, index]);
// if(selectedHeader?.id == 'name' || selectedHeader?.id == 'company_handle'){
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
// }
//   departmentChannels = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    departmentChannels = departmentChannels.filter((item) => item?.name?.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  }

  if (filterStatus === 'LIVE') {
    departmentChannels = departmentChannels.filter((item) => item.status === filterStatus);
  }
  if (filterStatus === 'On Hold') {
    departmentChannels = departmentChannels.filter((item) => item.status === filterStatus);
  }
  if (filterStatus === 'Verified') {
    departmentChannels = departmentChannels.filter((item) => item.verified);
  }
  if (filterRole !== 'all') {
    departmentChannels = departmentChannels.filter((item) => item.status?.toLowerCase() === filterRole?.toLowerCase());
  }

  return departmentChannels;
}
