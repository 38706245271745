import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
import bannerImg from '../../assets/Banner_square.jpg';
import { LoginForm } from '../../sections/auth/login';
import ezibotImg from '../../assets/bot.png';
import LoginLogo from 'src/components/LoginLogo';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  width: '100%',
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(3, 2, 0, 2),
  },
}));

const SectionStyle = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: `url(${bannerImg})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  fontFamily: 'Rubik, sans-serif', 
}));

const SlashStyle = styled('div')(() => ({
  height: '6px',
  width: '30px',
  background: 'white',
  borderRadius: '5px',
}));
const SectionTextStyle = styled('div')(() => ({
  display: 'contents',
  textAlign: 'center',
}));

const SectionWelcomeText = styled('p')(()=>({
  marginTop: '3rem !important',
  color: 'white',
  fontSize: 'medium',
  marginBottom:'1px !important'
}))
const SectionWelcomeBackText = styled('h1')(()=>({
  color: 'white',
  fontSize: '2.5rem',
  letterSpacing: '2px',
  fontWeight: 400,
  marginTop: '0.5rem !important',
  marginBottom:'2px !important'
}))
const SectionWelcomeBackTagelineText = styled('p')(()=>({
  color: 'white',
  fontSize: 'small',
  maxWidth: '80%',
  marginBottom:'16px !important',
  marginTop:'16px !important'
}))
const AdditionalText = styled('p')(()=>({
  position: 'absolute',
    bottom: '20px',
    left: '20px',
    width:'45%',
    display: 'flex',
    justifyContent: 'space-around',
}))


const ContentStyle = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  marginTop: '2rem',
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: '20px', // Adjust the font size as needed
  marginBottom: theme.spacing(0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  
  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <LoginLogo />
        </HeaderStyle>

        <SectionStyle>
          {mdUp && (
            <SectionTextStyle>
              <SectionWelcomeText>
              Nice to see you again              
              </SectionWelcomeText>
              <SectionWelcomeBackText >
                WELCOME BACK
              </SectionWelcomeBackText>
              <SlashStyle/>
              <SectionWelcomeBackTagelineText variant="p" sx={{ px: 5, mb: 5, mt:0.7, color: 'white' }}>
              This is for Conversational Intelligence.
              </SectionWelcomeBackTagelineText>
            </SectionTextStyle>
          )}
          {mdUp && (
            <AdditionalText>
              <a href="https://www.odioiq.com" target="_blank" style={{ color: 'white', textDecoration:'none'}}>Odio</a>
                        <a href="https://www.odioiq.com/blog/" target="_blank" style={{ color: 'white', textDecoration:'none'}}>Blogs</a>
                        <a href="https://www.odioiq.com/privacy-policy-2/" target="_blank" style={{ color: 'white', textDecoration:'none'}}>Privacy Policy</a>
                        <a href="https://www.odioiq.com/product/integrations/" target="_blank" style={{ color: 'white', textDecoration:'none' }}>Integrations</a>
                        <a href="https://www.odioiq.com/product/faq-2/" target="_blank" style={{ color: 'white', textDecoration:'none' }}>FAQs</a>
            </AdditionalText>
          )}
        </SectionStyle>

        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 1, width:'400px', fontSize:'40px', textAlign:'center' }}>
            <Box sx={{ flexGrow: 1 }}>
              <CustomTypography gutterBottom sx={{fontSize: '30px',mb: 0.5}} >
                Login to ODIO Bot
              </CustomTypography>
              <Typography sx={{ color: 'text.secondary',fontSize: '20px' }}>Enter your details below.</Typography>
            </Box>
          </Stack>

          <LoginForm />
        </ContentStyle>
      </RootStyle>
    </Page>
  );
}
