import { paramCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Drawer,
  CircularProgress,
} from '@mui/material';
import { PATH_APP } from '../../routes/paths';
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';
// _mock_
import { _userList } from '../../_mock';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../components/table';
// sections
import { OrganizationTableToolbar, OrganizationTableRow } from '../../sections/@dashboard/organization/list';
import { ApiEndpoints } from '../../config';
import { useSnackbar } from 'notistack';
import axios from '../../utils/axios';
import AddNewOrganization from 'src/sections/@dashboard/organization/AddNewOrganization';
import TableSkeleton from 'src/components/skeleton/TableSkeleton';

const STATUS_OPTIONS = ['all', 'active', 'banned'];

const ROLE_OPTIONS = ['all'];

//  users, businessHours, businessHolidays, businessEndPoints, accessTokens, organizationChannels
const TABLE_HEAD = [
  { id: 'id', label: 'Id', align: 'center' },
  { id: 'name', label: 'Name', align: 'center' },
  { id: 'contact_person', label: 'Contact Person', align: 'center' },
  { id: 'contact_number', label: 'Contact Number', align: 'center' },
  { id: 'email', label: 'Email', align: 'center' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];

// ----------------------------------------------------------------------

export default function Organization() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [filterName, setFilterName] = useState('');

  const [filterRole, setFilterRole] = useState('all');

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');

  const [organizations, setOrganizations] = useState([]);

  const STATUS_OPTIONS = ['all', 'active', 'inactive'];

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerOpen1, setDrawerOpen1] = useState();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    setDrawerOpen1()
  };

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };

  const handleDeleteRow = async (id) => {
    await axios.delete(ApiEndpoints.ORGANIZATION + '/' + 'delete' + '/' + id).then((res) => {
      try {
        if (res.data.status === 200) {
          enqueueSnackbar(res.data.message);
          fetchOrganizations();
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = organizations.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setOrganizations(deleteRows);
  };

  const handleEditRow = (id) => {
    setDrawerOpen(id);
    setDrawerOpen1(id);
  };

  const dataFiltered = applySortFilter({
    organizations,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    try {
      const response = await axios.get(ApiEndpoints.ORGANIZATION);
      if (response.data && response.data.status === 200) {
        // const transformedData = transformOrganizationData(response?.data?.data?.organizations);
        setOrganizations(response?.data?.data?.organizations);
        setLoading(false);
      }
    } catch (error) {
      console.error('Failed to fetch organizations:', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const getStatusCount = (status) => {
    if (status === 'all') {
      return organizations?.length;
    }
    return organizations?.filter((item) => item.status.toLowerCase() === status)?.length;
  };

  return (
    <>
      <Drawer anchor={'right'} open={Boolean(drawerOpen)} onClose={() => toggleDrawer()}>
        <AddNewOrganization isEdit={drawerOpen1} toggleDrawer={toggleDrawer} fetchOrganizations={fetchOrganizations} />
      </Drawer>
      <Page title="Organization: Listing" style={{ marginLeft: '20px', marginRight: '20px' }}>
        {/* <Container maxWidth={themeStretch ? false : 'lg'}> */}
        <HeaderBreadcrumbs
          heading="Organization Listing"
          links={[
            // { name: 'Dashboard', href: PATH_APP.general.dashboard },
            { name: '', href: PATH_APP.general.organization },
          ]}
          action={
            <Button variant="contained" onClick={() => toggleDrawer()} startIcon={<Iconify icon={'eva:plus-fill'} />}>
              New Organization
            </Button>
          }
        />

        <Card>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onChangeFilterStatus}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab + `(${getStatusCount(tab)})`} value={tab} />
            ))}
          </Tabs>

          <Divider />

          <OrganizationTableToolbar
            filterName={filterName}
            filterRole={filterRole}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            optionsRole={ROLE_OPTIONS}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={organizations.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      organizations.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={organizations.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      organizations.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {loading ? (
                    <TableSkeleton rowsPerPage={rowsPerPage} TABLE_HEAD={TABLE_HEAD} />
                  ) : (
                    <>
                      {dataFiltered?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <OrganizationTableRow
                          key={row.id}
                          row={row}
                          selected={selected.includes(row.id)}
                          onSelectRow={() => onSelectRow(row.id)}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                          onEditRow={() => handleEditRow(row)}
                        />
                      ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(page, rowsPerPage, organizations.length)}
                      />

                      <TableNoData isNotFound={isNotFound} titleText={`Add new organization`} description={`You have no organizations to view in ${filterStatus} tab`} />
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative', maxHeight: '54px' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            {/* <FormControlLabel
                                control={<Switch checked={dense} onChange={onChangeDense} />}
                                label="Dense"
                                sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                            /> */}
          </Box>
        </Card>
        {/* </Container> */}
      </Page>
    </>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ organizations, comparator, filterName, filterStatus, filterRole }) {
  const stabilizedThis = organizations.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  organizations = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    organizations = organizations.filter((item) => item?.name?.toLowerCase()?.indexOf(filterName.toLowerCase()) !== -1);
  }

  if (filterStatus !== 'all') {
    organizations = organizations.filter((item) => item?.status?.toLowerCase() === filterStatus);
  }

  if (filterRole !== 'all') {
    organizations = organizations.filter((item) => item.role === filterRole);
  }

  return organizations;
}
