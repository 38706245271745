import { paramCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  CircularProgress,
  Skeleton,
  TableRow,
  TableCell,
} from '@mui/material';
import { PATH_APP } from '../../routes/paths';
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';
// mock
import { _userList } from '../../_mock';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../components/table';
// sections
import { ApiEndpoints } from '../../config';
import axios from '../../utils/axios';
import AddNewCampaign from '../../sections/@dashboard/campaign/AddNewCampaign';
import Drawer from '@mui/material/Drawer';
import CampaignTableToolbar from '../../sections/@dashboard/campaign/list/CampaignTableToolbar';
import CampaignTableRow from '../../sections/@dashboard/campaign/list/CampaignTableRow';
import { useSnackbar } from 'notistack';
import TableSkeleton from 'src/components/skeleton/TableSkeleton';

const ROLE_OPTIONS = ['all'];

// const TABLE_HEAD = [
//   { id: 'id', label: 'Campaign Id', align: 'center' },
//   { id: 'name', label: 'Campaign Name', align: 'center' },
//   { id: 'channel_type', label: 'Campaign Type', align: 'center' },
//   { id: 'agent', label: 'Campaign Priority', align: 'center' },
//   { id: 'auditor', label: 'Status', align: 'center' },
//   { id: 'action', label: 'Action', align: 'center' },
// ];

const TABLE_HEAD = [
  { id: 'id', label:  'Campaign Id', align: 'center' },
  { id: 'name', label:  'Campaign Name', align: 'center' },
  { id: 'description', label: 'Description', align: 'center' }, 
  { id: 'whatsapp_template', label: 'WhatsApp Template', align: 'center' }, 
  { id: 'group_name', label: 'Groups', align: 'center' }, 
  { id: 'segment_name', label: 'Segments', align: 'center' }, 
  { id: 'status', label: 'Status', align: 'center' }, 
  { id: 'organisation_departments', label: 'Departments', align: 'center' }, 
  { id: 'action', label: 'Action', align: 'center' }, 
];

// ----------------------------------------------------------------------

export default function Campaigns() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [filterName, setFilterName] = useState('');

  const [filterRole, setFilterRole] = useState('all');

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');

  const [departments, setDepartments] = useState([]);

  const [loading, setLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    setIsEdit();
  };
  // function transformDepartmentData(departments) {
  //   return departments?.map((dept) => {
  //     return {
  //       id: dept.id,
  //       name: dept.name,
  //       channel_type: dept.campaign_type,
  //       auditor: dept.status,
  //       agent: dept.campaign_priority,
  //       status: dept.status // Assuming you just need the count of agents
  //     };
  //   });
  // }

  useEffect(() => {
    fetchDepartments();
  }, [page, rowsPerPage]);


  // const fetchDepartments = async () => {
  //   setLoading(true);
  //   await axios
  //     .get(`${ApiEndpoints.CAMPAIGNS}?page=${page}&size=${rowsPerPage}`)
  //     .then((res) => {
  //       if (res?.data?.status == 200) {
  //         const transformedData = response.data.data.content;
  //         setDepartments(transformedData);
  //         setTotalPages(response?.data?.data?.total_pages);
  //         setCurrentPage(response?.data?.data?.number);
  //         setTotalCount(response?.data?.data?.total_elements);
  //         setLoading(false);
  //         console.log(transformedData);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Failed to fetch departments:', error);
  //       setLoading(false);
  //     })
  //     .finally(() => setLoading(false));
  // };


  const fetchDepartments = async () => {
    setLoading(true);
    let url = `${ApiEndpoints.CAMPAIGNS}?page=${page+1}&size=${rowsPerPage}`;
    try {
      const response = await axios.get(url);
      if (response?.data?.status == 200) {
        setDepartments(response?.data?.data?.data);
        setTotalPages(response?.data?.data?.total_pages);
        setCurrentPage(response?.data?.data?.current_page-1);
        setTotalCount(response?.data?.data?.total_elements);
        setLoading(false);

      }
    } catch (error) {
      console.error('Failed to fetch', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };


  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };

  const handleDeleteRow = async (id) => {
    await axios
      .delete(ApiEndpoints.DELETECAMPAIGN + '/' + id)
      .then((res) => {
        if (res.data.status === 200) {
          enqueueSnackbar(res.data.message, { variant: 'success' });
          fetchDepartments();
        } else {
          enqueueSnackbar(res.data.message, { variant: 'error' });
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Error while deleting', { variant: 'error' });
      });
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = departments.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setDepartments(deleteRows);
  };

  const [isEdit, setIsEdit] = useState();
  const handleEditRow = (id) => {
    toggleDrawer(id);
    setIsEdit(id);
  };
  const dataFiltered = applySortFilter({
    departments,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered?.length && !!filterName) ||
    (!dataFiltered?.length && !!filterRole) ||
    (!dataFiltered?.length && !!filterStatus);

  // const getDepartments = async () => {
  //   const departments = await axios.get(ApiEndpoints.DEPARTMENTS)
  //   return departments
  // }

  // useEffect(() => {
  //   getDepartments().then(res => {
  //     const depts = res.data.data.departments;
  //     console.log('depts', depts);
  //     setDepartments([...departments, ...depts])
  //   })
  // }, [])

  const STATUS_OPTIONS = ['all', 'pending', 'running', 'completed', 'paused'];
  const getStatusCount = (status) => {
    if (status === 'all') {
      return departments?.length;
    }
    return departments?.filter((item) => item.status.toLowerCase() === status)?.length;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    if (totalPages === page + 1) {
      setPage(0);
    }
  };
  return (
    <>
      {drawerOpen && (
        <Drawer anchor={'right'} 
        open={drawerOpen} 
        onClose={() => toggleDrawer()}>
          <AddNewCampaign isEdit={isEdit} toggleDrawer={toggleDrawer} fetchDepartments={fetchDepartments} />
        </Drawer>
      )}
      <Page title="Deparment: List" style={{ marginLeft: '20px', marginRight: '20px' }}>
        {/* <Container maxWidth={themeStretch ? false : 'lg'}> */}
        <HeaderBreadcrumbs
          heading="Campaigns List"
          links={[
            { name: 'Dashboard', href: PATH_APP.general.dashboard },
            { name: 'Campaigns', href: PATH_APP.management.campaigns },
          ]}
          action={
            <Button variant="contained" onClick={toggleDrawer} startIcon={<Iconify icon={'eva:plus-fill'} />}>
              New Campaign
            </Button>
          }
        />

        <Card>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onChangeFilterStatus}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab + `(${getStatusCount(tab)})`} value={tab} />
            ))}
          </Tabs>

          <Divider />

          <CampaignTableToolbar
            filterName={filterName}
            filterRole={filterRole}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            optionsRole={ROLE_OPTIONS}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected?.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected?.length}
                  rowCount={departments?.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      departments.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={departments?.length}
                  numSelected={selected?.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      departments.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {loading ? (
                    <TableSkeleton rowsPerPage={rowsPerPage} TABLE_HEAD={TABLE_HEAD} />
                  ) : (
                    <>
                      {dataFiltered?.map((row) => (
                        <CampaignTableRow
                          key={row.id}
                          row={row}
                          selected={selected.includes(row.id)}
                          onSelectRow={() => onSelectRow(row.id)}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                          onEditRow={() => handleEditRow(row)}
                        />
                      ))}

                      <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, totalCount)} />
                      <TableNoData isNotFound={isNotFound} titleText={`Add new campaigns`} description={`You have no campaigns to view in ${filterStatus} tab`} />
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative', maxHeight: '54px' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Card>
        {/* </Container> */}
      </Page>
    </>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ departments, comparator, filterName, filterStatus, filterRole }) {
  // const stabilizedThis = departments.map((el, index) => [el, index]);

  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });

  // departments = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    departments = departments?.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  }

  if (filterStatus !== 'all') {
    departments = departments?.filter((item) => item.status.toLowerCase() === filterStatus);
  }

  if (filterRole !== 'all') {
    departments = departments?.filter((item) => item.role === filterRole);
  }

  return departments;
}
