import { GridRow } from '@mui/x-data-grid';
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  CircularProgress,
  FormHelperText,
  Skeleton,
  TableRow,
  TableCell,
} from '@mui/material';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import './Analytics.css';
import NoDataFound from './NoDataFound';
import Chart from '../../assets/chart1.png';
import Charrt from '../../assets/chart2.png';
import Graph from '../../assets/graph1.png';
import { InfoOutlined } from '@mui/icons-material';
import ApexChart from './chart';
import { DatePicker } from 'antd';
import { useState } from 'react';
import moment from 'moment';
import { Padding } from '@mui/icons-material';
import { ChatSidebar } from 'src/sections/@dashboard/chat';
const { RangePicker } = DatePicker;
import './Failedmessages.css';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { ApiEndpoints } from 'src/config';
import axios from '../../utils/axios';
import Page from 'src/components/Page';
import { useForm } from 'react-hook-form';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ['Department 1', 'Department 2', 'Department 3', 'Department 4', 'Department 5'];
function getStyles(name, departmentName, theme) {
  return {
    fontWeight: departmentName.includes(name) ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
  };
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
export default function Analytics() {
  // const [value, setValue] = React.useState(0);
  const defaultValues = {};
  const [department, setDepartment] = useState([]);
  const NewUserSchema = Yup.object().shape({});
  const [defaultRange, setDefaultRange] = useState([]);
  const [botSessionData, setBotSessionData] = useState([]);
  const [totalConversationData, setTotalConversationData] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [dates, setDates] = useState([]);
  const parsedDates = dates.map((date) => {
    const [year, day, month] = date.split('-');
    // const iso= new Date(Date.UTC(year, month - 1, day)).toISOString();
    // return `ISODate("${iso.replace('Z', '+0000')}")`;
    return new Date(Date.UTC(year, month - 1, day)).toISOString();

  });
  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
  } = methods;
  useEffect(() => {
    // Set today's date as the default range
    const today = moment();
    const startOfDay = today.startOf('day');
    const endOfDay = today.endOf('day');
    setDefaultRange([startOfDay, endOfDay]);
  }, []);
  const formValues = getValues();

  const getAgentsDepartments = async () => {
    try {
      const response = await axios.get(ApiEndpoints.DEPARTMENTS);
      const departments = response.data.data;
      // console.log(departments);
      setDepartments(departments);
    } catch (error) {
      console.error('Failed to fetch departments:', error);
      enqueueSnackbar('Failed to fetch departments!', { variant: 'error' });
    }
  };
  useEffect(() => {
    getAgentsDepartments();
  }, []);
  const getBotsessionData = async (startDate, endDate, departmentId) => {
    try {
      const response = await axios.get(
        `${ApiEndpoints.ANALYTICS_BOT}?start_date=${startDate}&end_date=${endDate}&department=${departmentId}`
      );
      if (response?.data?.status === 200) {
        setBotSessionData(response?.data);
        // console.log("bot data",botSessionData);
      } 
    } catch (error) { 
      console.error('Failed to fetch Bot Conversatison:', error);
      enqueueSnackbar('Failed to fetch Bot Conversatison!', { variant: 'error' });
    } 
  }; 
  const getTotalConversation = async (startDate, endDate, departmentId) => {
    try {
      const response = await axios.get(
        `${ApiEndpoints.ANALYTICS_TOTAL}?start_date=${startDate}&end_date=${endDate}&department=${departmentId}`
      );
      if (response?.data?.status === 200) {
        setTotalConversationData(response?.data);
        console.log("total data",totalConversationData);
      } 
    } catch (error) { 
      console.error('Failed to fetch Bot Conversatison:', error);
      enqueueSnackbar('Failed to fetch Bot Conversatison!', { variant: 'error' });
    } 
  }; 
 
  const handleDepartmentChange = (event) => {
    setValue('department', event.target.value);
    if (event.target.value && parsedDates?.length > 0) {
      getTotalConversation(parsedDates?.[0], parsedDates?.[1], event.target.value);
      getBotsessionData(parsedDates?.[0], parsedDates?.[1], event.target.value);
    } 
  }; 
  return (
    <div className="analytics_wrapper">
      <Page title="Analytics Dashboard" style={{ marginLeft: '20px', marginRight: '20px' }}>
        <section>
          <Box sx={{ width: '100%' }}>
            <div className="analytics-headd">
              <div className="chat-range">
                <RangePicker
                  className="date-css"
                  onChange={(values) => {
                    setDates(
                      values.map((item) => {
                        return moment(item).format('YYYY-DD-MM');
                      })
                    );
                  }}
                />
              </div>
              <div>
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="department-multi-select-label">Department</InputLabel>
                  <Select
                    labelId="department-select-label"
                    id="department-select"
                    name="department"
                    value={formValues?.department}
                    onChange={(e) => handleDepartmentChange(e)}
                    input={<OutlinedInput label="Department" />}
                  >
                    {departments?.map((department) => (
                      <MenuItem key={department.id} value={department.id}>
                        {department.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={Boolean(errors?.department?.message)}>
                    {errors?.department?.message}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
          </Box>
        </section>
        <section className="migrate-child">
          <div className="parent">
            <div className="box1">
              <p>
                Open Conversations{' '}
                <Tooltip title="count of unresolved conversation" arrow placement="top">
                  <InfoOutlined fontSize="10px" />
                </Tooltip>
              </p>
            </div>
            <p className="ptext">4</p>
          </div>
          <div className="parent">
            <div className="box1">
              <p>
                Resolved Conversations
                <Tooltip title="count of resolved conversation" arrow placement="top">
                  <InfoOutlined fontSize="10px" />
                </Tooltip>
              </p>
            </div>
            <p className="ptext">10</p>
          </div>
          <div className="parent">
            <div className="box1">
              <p>
                Web Conversations{' '}
                <Tooltip title="count of Live chat conversation" arrow placement="top">
                  <InfoOutlined fontSize="10px" />
                </Tooltip>
              </p>
            </div>
            <p className="ptext">3</p>
          </div>
          <div className="parent">
            <div className="box1">
              <p>
                Total Conversations{' '}
                <Tooltip title="total Whatsapp conversatio" arrow placement="top">
                  <InfoOutlined fontSize="10px" />
                </Tooltip>
              </p>
            </div>
            <p className="ptext">1</p>
          </div>
          <div className="parent">
            <div className="box1">
              <p>
                Whatsapp Template Messages{' '}
                <Tooltip title="count of whatsapp template message initiated" arrow placement="top">
                  <InfoOutlined fontSize="10px" />
                </Tooltip>
              </p>
            </div>
            <p className="ptext">9</p>
          </div>
          <div className="parent">
            <div className="box1">
              <p>
                Whatsapp Session Messages{' '}
                <Tooltip title="count of whatsapp reply messages within 24 hrs window" arrow placement="top">
                  <InfoOutlined fontSize="10px" />
                </Tooltip>
              </p>
            </div>
            <p className="ptext">5</p>
          </div>
          <div className="parent">
            <div className="box1">
              <p>
                Active Conversations{' '}
                <Tooltip title="open conversation within 24 hours" arrow placement="top">
                  <InfoOutlined fontSize="10px" />
                </Tooltip>
              </p>
            </div>
            <p className="ptext">6</p>
          </div>
          <div className="parent">
            <div className="box1">
              <p>
                Whatsapp Business Initiated{' '}
                <Tooltip title="conversation initiated by the business like utility/marketing" arrow placement="top">
                  <InfoOutlined fontSize="10px" />
                </Tooltip>
              </p>
            </div>
            <p className="ptext">2</p>
          </div>
        </section>
        <section className="second-section">
          <div className="parent-1">
            <div className="box-3">
              <div className="inside-box">
                <div className="inside-pad">
                  <div className="css-126w66a">
                    <p>
                      WhatsApp Template Messages Vs Session Messages{' '}
                      <Tooltip title="day wise WhatsApp Template Messages Vs Session Messages" arrow placement="top">
                        <InfoOutlined fontSize="10px" />{' '}
                      </Tooltip>
                    </p>
                  </div>
                </div>
                <ApexChart isWaBar={true} />
              </div>
            </div>
          </div>
          <div className="parent-2">
            <div className="box-3">
              <div className="inside-box">
                <div className="inside-pad">
                  <div className="css-126w66a">
                    <p>
                      Whatsapp message status{' '}
                      <Tooltip title="whatsapp outgoing messages by different statuses" arrow placement="top">
                        {' '}
                        <InfoOutlined fontSize="10px" />
                      </Tooltip>
                    </p>
                  </div>
                </div>
                <ApexChart isBar={true} />

                {/* <p class="css-izwzdw">Days Vs Count of conversations created</p> */}
              </div>
            </div>
          </div>
        </section>
        <Box sx={{ flexGrow: 1 }}>
          <section className="third-one">
            <div className="parent-4">
              <div className="box-3">
                <div className="inside-box">
                  <div className="inside-pad">
                    <div className="css-126w66a">
                      <p>
                        Conversations{' '}
                        <Tooltip title="Day wise total number of conversation created" arrow placement="top">
                          <InfoOutlined fontSize="10px" />
                        </Tooltip>
                      </p>
                    </div>
                  </div>
                  <ApexChart chartdata={totalConversationData}/>
                </div>
              </div>
            </div>
          </section>
          <section className="fourth-one">
            <div className="parent-4">
              <div className="box-3">
                <div className="inside-box">
                  <div className="inside-pad">
                    <div className="css-126w66a">
                      <p>
                        Bot Sessions{' '}
                        <Tooltip title="day wise count of conversation handeld by bot" arrow placement="top">
                          <InfoOutlined fontSize="10px" />
                        </Tooltip>
                      </p>
                    </div>
                  </div>
                  <ApexChart chartdata={botSessionData} />
                </div>
              </div>
            </div>
          </section>
        </Box>
      </Page>
    </div>
  );
}
