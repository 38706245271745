import { paramCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Navigate, Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Drawer,
  CircularProgress,
} from '@mui/material';
import { PATH_APP } from '../../routes/paths';
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';
// _mock_
import { _userList } from '../../_mock';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../components/table';
// sections
import { DepartmentTableToolbar, DepartmentTableRow } from '../../sections/@dashboard/department/list';
import { ApiEndpoints } from '../../config';
import { useSnackbar } from 'notistack';
import axios from '../../utils/axios';
import AddNewDepartment from 'src/sections/@dashboard/department/AddNewDepartment';
import AddNewAuditor from 'src/sections/@dashboard/auditor/AddNewAuditor';
import TableSkeleton from 'src/components/skeleton/TableSkeleton';

const STATUS_OPTIONS = ['all', 'active', 'banned'];

const ROLE_OPTIONS = ['all'];

const TABLE_HEAD = [
  { id: 'id', label: 'Id', align: 'left' },
  { id: 'name', label: 'Department Name', align: 'left' },
  { id: 'description', label: 'Department Description', align: 'left' },
  // { id: 'channel_type', label: 'Channel Type', align: 'left' },
  // { id: 'agent', label: 'Agents', align: 'center' },
  // { id: 'auditor', label: 'Auditor', align: 'center' },
  { id: 'action', label: 'Action', align: 'left' },
];

// ----------------------------------------------------------------------

export default function Departments() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [filterName, setFilterName] = useState('');

  const [filterRole, setFilterRole] = useState('all');

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');

  const [departments, setDepartments] = useState([]);

  const STATUS_OPTIONS = ['all'];

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [auditorDrawerOpen, setAuditorDrawerOpen] = useState(false);
  const [drawerOpen1, setDrawerOpen1] = useState();
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount,setTotalCount] = useState(0);
  
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    setDrawerOpen1()
  };
  const toggleAuditorDrawer = (row) => {
    localStorage.setItem('departmentAuditorDetails', JSON.stringify(row.id));

    // setAuditorDrawerOpen(!auditorDrawerOpen);
    // setDrawerOpen1(row);
  };

  const toggleAgentDrawer = (row) => {
    localStorage.setItem('departmentAgentDetails', JSON.stringify(row.id));
    navigate(PATH_APP.management.agents);
  };
  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };

  const handleDeleteRow = async (id) => {
    await axios.delete(ApiEndpoints.DEPARTMENTS + '/' + id).then((res) => {
      try {
        if (res.data.status === 200) {
          enqueueSnackbar(res.data.message, { variant: 'success' });
          fetchDepartments();
        }else{
          enqueueSnackbar(res.data.message, { variant: 'error' });
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = departments.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setDepartments(deleteRows);
  };

  const handleEditRow = (id) => {
    //navigate(PATH_APP.management.updateDepartment(id));
    setDrawerOpen1(id);
    setDrawerOpen(!drawerOpen);
  };

  const dataFiltered = applySortFilter({
    departments,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  function transformDepartmentData(departments) {
    return departments.map((dept) => {
      const primaryChannel =
        dept?.department_channels?.length > 0 ? dept?.department_channels[0]?.channel?.name : 'No Channel';
      const auditorName = dept?.auditor?.name;
      let agentNames = [];

      if (dept?.agent?.length > 0) {
        dept.agent.forEach((agent) => agentNames.push(agent.name));
      }
      else {
        agentNames = [];
      }

      return {
        id: dept.id,
        name: dept?.name,
        description: dept?.description,
        channel_type: primaryChannel,
        auditor: auditorName,
        agent: agentNames,
      };
    });
  }

  useEffect(() => {
    fetchDepartments();
  }, [page,rowsPerPage]);

  const fetchDepartments = async () => {
    try {
      const response = await axios.get(`${ApiEndpoints.DEPARTMENTS}?page=${page+1}&size=${rowsPerPage}`);
      if (response.data && response.data.status === 200) {
        const transformedData = transformDepartmentData(response?.data?.data?.data);
        setDepartments(transformedData);
        setTotalPages(response?.data?.data?.total_pages);
        setCurrentPage(response?.data?.data?.current_page-1);
        setTotalCount(response?.data?.data?.total_elements);
        setLoading(false);
      }
    } catch (error) {
      console.error('Failed to fetch departments:', error);
      setLoading(false);
    }finally{
      setLoading(false)
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    if(totalPages === (page+1) ){
      setPage(0);
    }
  };

  return (
    <>
      {drawerOpen && <Drawer anchor={'right'} open={drawerOpen} onClose={() => toggleDrawer()}>
        <AddNewDepartment isEdit={drawerOpen1} toggleDrawer={toggleDrawer} fetchDepartments={fetchDepartments} />
      </Drawer>}
      {auditorDrawerOpen && <Drawer anchor={'right'} open={auditorDrawerOpen} onClose={() => toggleAuditorDrawer()}>
        <AddNewAuditor toggleDrawer={toggleAuditorDrawer} fetchDepartments={fetchDepartments} />
      </Drawer>}
      <Page title="Deparment: List" style={{ marginLeft: '20px', marginRight: '20px' }}>
        {/* <Container maxWidth={themeStretch ? false : 'lg'}> */}
          <HeaderBreadcrumbs
            heading="My Departments List"
            links={[
              { name: 'Dashboard', href: PATH_APP.general.dashboard },
              { name: 'Departments', href: PATH_APP.general.departments },
            ]}
            action={
              <Button variant="contained" onClick={() => toggleDrawer()} startIcon={<Iconify icon={'eva:plus-fill'} />}>
                New Department
              </Button>
            }
          />

          <Card>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={filterStatus}
              onChange={onChangeFilterStatus}
              sx={{ px: 2, bgcolor: 'background.neutral' }}
            >
              {STATUS_OPTIONS.map((tab) => (
                <Tab disableRipple key={tab} label={tab + `(${departments?.length})`} value={tab} />
              ))}
            </Tabs>

            <Divider />

            <DepartmentTableToolbar
              filterName={filterName}
              filterRole={filterRole}
              onFilterName={handleFilterName}
              onFilterRole={handleFilterRole}
              optionsRole={ROLE_OPTIONS}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                {selected.length > 0 && (
                  <TableSelectedActions
                    dense={dense}
                    numSelected={selected.length}
                    rowCount={departments.length}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        departments.map((row) => row.id)
                      )
                    }
                    actions={
                      <Tooltip title="Delete">
                        <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                          <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                      </Tooltip>
                    }
                  />
                )}

                <Table size={dense ? 'small' : 'medium'}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={departments.length}
                    numSelected={selected.length}
                    onSort={onSort}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        departments.map((row) => row.id)
                      )
                    }
                  />

                  <TableBody>
                  {loading ? (
                    <TableSkeleton rowsPerPage={rowsPerPage} TABLE_HEAD={TABLE_HEAD} />
                  ) : (
                    <>
                    {dataFiltered
                      ?.map((row) => (
                        <DepartmentTableRow
                          key={row.id}
                          row={row}
                          selected={selected.includes(row.id)}
                          onSelectRow={() => onSelectRow(row.id)}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                          onEditRow={() => handleEditRow(row)}
                          assignAuditor={() => {
                            navigate(PATH_APP.management.auditors);
                            // setAuditorDrawerOpen();
                            toggleAuditorDrawer(row);
                          }}
                          assignAgent={() => {
                            toggleAgentDrawer(row);
                          }}
                        />
                      ))}

                    <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, totalCount)} />

                    <TableNoData isNotFound={isNotFound} titleText={`Add new departments`} description={`You have no departments to view `} />
                    </>
                  )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <Box sx={{ position: 'relative', maxHeight: '54px' }}>
            <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            />

              {/* <FormControlLabel
                control={<Switch checked={dense} onChange={onChangeDense} />}
                label="Dense"
                sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
              /> */}
            </Box>
          </Card>
        {/* </Container> */}
      </Page>
    </>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ departments, comparator, filterName, filterStatus, filterRole }) {
  // const stabilizedThis = departments.map((el, index) => [el, index]);

  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });

  // departments = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    departments = departments.filter((item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
  }

  if (filterStatus !== 'all') {
    departments = departments.filter((item) => item.status === filterStatus);
  }

  if (filterRole !== 'all') {
    departments = departments.filter((item) => item.role === filterRole);
  }

  return departments;
}
