import { paramCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TablePagination,
  FormControlLabel,
  Drawer,
} from '@mui/material';
import { PATH_APP } from '../../routes/paths';
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';
// _mock_
import { _userList } from '../../_mock';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../components/table';
// sections
import { AgentTableToolbar, AgentTableRow } from '../../sections/@dashboard/agent/list';
import { ApiEndpoints } from '../../config';
import axios from '../../utils/axios';
import AddNewAgent from 'src/sections/@dashboard/agent/AddNewAgent';
import { set } from 'lodash';
import { useSnackbar } from 'notistack';
import CustomConfirmBox from 'src/components/CustomConfirmBox';
import TableSkeleton from 'src/components/skeleton/TableSkeleton';

const ROLE_OPTIONS = ['all'];

const TABLE_HEAD = [
  { id: 'id', label: 'Id', align: 'center' },
  { id: 'name', label: 'Name', align: 'center' },
  // { id: 'department', label: 'Department', align: 'center' },
  { id: 'mobile_number', label: 'Mobile No.', align: 'center' },
  { id: 'email', label: 'Email', align: 'center' },
  // { id: 'status', label: 'Status', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];

const Agents = () => {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');

  const [agents, setAgents] = useState([]);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isEdit, setIsEdit] = useState();
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [rowDeleteId, setRowDeleteId] = useState();
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount,setTotalCount] = useState(0);
  const addAgetForDept = localStorage.getItem('departmentAgentDetails');
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    setIsEdit();
  };

  // const countStatuses = (agents) => {
  //     const statusCounts = {
  //         all: agents.length,
  //         online: 0,
  //         away: 0,
  //         busy: 0,
  //         offline: 0
  //     };

  //     agents.forEach(agent => {
  //         if (agent.status in statusCounts) {
  //             statusCounts[agent.status]++;
  //         }
  //     });

  //     const STATUS_OPTIONS = [
  //         `all(${statusCounts.all})`,
  //         `online(${statusCounts.online})`,
  //         `away(${statusCounts.away})`,
  //         `busy(${statusCounts.busy})`,
  //         `offline(${statusCounts.offline})`
  //     ];

  //     return STATUS_OPTIONS;
  // };
  // const statusOptions = countStatuses(agents);
  const STATUS_OPTIONS = [
    'all',
    //  'online', 'away', 'busy', 'offline'
  ];
  const getStatusCount = (status) => {
    if (status === 'all') {
      return agents?.length;
    }
    // return agents?.filter(item => item?.status?.toLowerCase() === status)?.length;
  };

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };

  const handleDeleteRow = async (id) => {
    const deleteRow = await axios.delete(ApiEndpoints.DELETEAGENT + '/' + id);
    if (deleteRow?.data?.status === 200) {
      enqueueSnackbar(deleteRow.data.message, { variant: 'success' });
      getAgents();
    }else{
      enqueueSnackbar(deleteRow.data.message, { variant: 'error' });
    }
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = agents.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setAgents(deleteRows);
  };

  const handleEditRow = (id) => {
    toggleDrawer(id);
    setIsEdit(id);
  };

  const dataFiltered = applySortFilter({
    agents,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  const getAgents = async () => {
    setLoading(true);
    let res;
    try {
       res = await axios.get(`${ApiEndpoints.ALL_AGENTS}?page=${page+1}&size=${rowsPerPage}`);
      if (res?.data?.status === 200) {
        // const { content, total_pages, number, total_elements } = res?.data?.data?.data;
        setAgents(res?.data?.data?.data);
        setTotalPages(res?.data?.data?.total_pages);
        setCurrentPage(res?.data?.data?.current_page-1);
        setTotalCount(res?.data?.data?.total_elements);
      }
    } catch (err) {
      console.error('Failed to fetch agents:', err);
      enqueueSnackbar(res?.data?.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAgents();
  },[page, rowsPerPage]);

  useEffect(() => {
    if (addAgetForDept) {
      toggleDrawer();
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    if(totalPages === (page+1) ){
      setPage(0);
    }
  };
  return (
    <>
      <Drawer
        anchor={'right'}
        open={drawerOpen}
        onClose={() => {
          toggleDrawer();
          localStorage.removeItem('departmentAgentDetails');
        }}
      >
        <AddNewAgent isEdit={isEdit} toggleDrawer={toggleDrawer} getAgents={getAgents} />
      </Drawer>
      <Page title="Agent: List" style={{ marginLeft: '20px', marginRight: '20px' }}>
        {/* <Container maxWidth={themeStretch ? false : 'lg'}> */}
        <HeaderBreadcrumbs
          heading="My Agent Listing"
          links={[
            { name: 'Dashboard', href: PATH_APP.general.dashboard },
            { name: 'Agents', href: PATH_APP.general.agents },
          ]}
          action={
            <Button variant="contained" onClick={() => toggleDrawer()} startIcon={<Iconify icon={'eva:plus-fill'} />}>
              New Agent
            </Button>
          }
        />

        <Card>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onChangeFilterStatus}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab + `(${getStatusCount(tab)})`} value={tab} />
            ))}
          </Tabs>
          <Divider />

          <AgentTableToolbar
            filterName={filterName}
            filterRole={filterRole}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            optionsRole={ROLE_OPTIONS}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={agents.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      agents.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  styles={{ width: '100%' }}
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={agents?.length}
                  numSelected={selected?.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      agents?.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {loading ? (
                    <TableSkeleton rowsPerPage={rowsPerPage} TABLE_HEAD={TABLE_HEAD} />
                  ) : (
                    <>
                      {dataFiltered?.map((row) => (
                        <AgentTableRow
                          key={row.id}
                          row={row}
                          selected={selected.includes(row.id)}
                          onSelectRow={() => onSelectRow(row.id)}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                          onEditRow={() => handleEditRow(row.id)}
                        />
                      ))}

                      <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, totalCount)} />

                      <TableNoData isNotFound={isNotFound} titleText={`Add new agnets`} description={`You have no agents to view`}/>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative', maxHeight: '54px' }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Card>
        {/* </Container> */}
      </Page>
    </>
  );
};
export default Agents;

function applySortFilter({ agents, comparator, filterName, filterStatus, filterRole }) {
  let filteredAgents = [...agents];

  if (filterName) {
    filteredAgents = filteredAgents.filter(
      (item) => item?.name?.toLowerCase().indexOf(filterName?.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'all') {
    filteredAgents = filteredAgents.filter((item) => item.status === filterStatus);
  }

  if (filterRole !== 'all') {
    filteredAgents = filteredAgents.filter((item) => item.role === filterRole);
  }

  return filteredAgents;
}
